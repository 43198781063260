@import '../../../mainStyle/mixins.scss';

.card {
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 13px;
  border-left: 8px solid var(--root-color-yellow);
  margin-left: -7px;

  &:not(:last-child) {
    margin-bottom: 60px;

    @include tablet {
      margin-bottom: 20px;
      max-width: 398px;
      min-height: 110px;
      padding: 8px;
    }

    @include mobile {
      margin-left: -4px;
    }

    @include miniMobile {
      margin-bottom: 12px;
    }
  }

  @include tablet {
    border-left: 4px solid var(--root-color-yellow);
  }

  @include mobile {
    min-height: 110px;
    padding: 6px 8px;
  }

  @include miniMobile {
    min-height: 90px;
    padding: 7px 16px 7px 8px;
  }
}

.link {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: var(--root-color-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 129px;
  height: 24px;
  transition: 0.3s ease-out !important;
  gap: 12px;

  &:hover {
    font-weight: 700;
  }

  &:active {
    font-weight: 800;
  }

  @include mobile {
    justify-self: end;
  }
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: var(--root-color-white);

  @include tablet {
    font-size: 18px
  }

  @include mobile {
    font-size: 18px;
  }

  @include miniMobile {
    font-size: 14px;
  }
}

.subtitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  color: var(--root-color-white);
}

@include mediumDesktop {
  .subtitle {
    font-size: 22px;

    @include tablet {
      font-size: 16px;
    }

    @include mobile {
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 14px;
    }

    @include miniMobile {
      font-size: 12px;
      margin: 6px 0 8px;
    }
  }
}
