@import "../../../../mainStyle/mixins.scss";

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  text-transform: uppercase;

  @include mediumDesktop {
    width: 60%;
    width: 100%;
  }

  @include tablet {
    width: 100%;
  }
}

.link {
  color: var(--root-color-white);
  font-weight: 400;
  font-size: 16px;

  @include tablet {
    font-size: 11px;
  }
}

.linkWrap {
    display: inline-block;
    font-size: 0;
}

.linkWrap a {
    display:inline-block;
    text-transform: uppercase;
}

.link:hover {
    font-weight: 600;
}

.link::before {
    display: block;
    content: attr(title);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

.activeLink {
  color: var(--root-color-white);
  font-weight: 600;
  font-size: 16px;

  @include tablet {
    font-weight: 500;
    font-size: 11px;
  }
}
