@import "../../../mainStyle/mixins.scss";

.main {
  display: flex;
  align-items: flex-end;
  max-width: 588px;
  width: 100%;
  height: 324px;
}

.imgWrapper {
  object-fit: cover;
  z-index: 99;
  -webkit-mask-size: cover;
  mask-size: cover;
  margin-right: 10px;

  & img {
    object-fit: cover;
    width: 223px;
    height: 324px;
    user-select: none;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-right: 40px;
}

.imgWrapper {
  mask-image: url("../../../assets/image//maskImg.svg");
  -webkit-mask-image: url("../../../assets/image/maskImg.svg");
}

.whiteColor {
  font-family: var(--fontMons) !important;
  color: var(--root-color-white) !important;
}

.imgSubtitle {
  margin-top: 10px !important;
}

@include mediumDesktop {
  .main {
    display: flex;
    align-items: flex-end;
    max-width: 510px;
    width: 100%;
    height: 275px;
  }

  .imgWrapper {
    & img {
      width: 190px;
      height: 275px;
      user-select: none;
    }
  }
}

@include tablet {
  .main {
    max-width: 354px;
    height: 148px;
  }

  .imgWrapper {
    margin-right: 4px;

    & img {
      width: 101px;
      height: 148px;
    }
  }

  .info {
    padding-right: 0;
  }
}

@include mobile {
  .main {
    max-width: 311px;
    width: 100%;
    height: 148px;
  }

  .imgWrapper {
    margin-right: 4px;

    & img {
      width: 101px;
      height: 148px;
    }
  }

  .info {
    padding-right: 0;
  }
}

@include miniMobile {
  .main {
    height: 120px;
    width: fit-content;
  }

  .imgWrapper {
    margin-right: 4px;

    & img {
      width: 81px;
      height: 120px;
    }
  }

  .imgSubtitle {
    margin-top: 4px !important;
  }
}
