@import "../../../mainStyle/mixins.scss";
$halfPart: 50%;

.anchorButton {
  position: fixed;
  bottom: 55px;
  right: 52px;
  display: flex;
  align-items: center !important;
  flex-direction: column;
  justify-content: center !important;
  z-index: 99;
  @include mobile {
    right: 58px;
  }
}
.actionBtn {
  width: 50px;
  height: 50px;
  z-index: 9999;
  background: var(--root-color-yellow);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.4);
  border-radius: $halfPart;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box !important;
  border: 7px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent !important;

  @include mobile {
    width: 40px;
    height: 40px;
  }

  > img {
    width: 25px;
    height: 25px;
    position: absolute;
  }

  .actionBlock {
    position: absolute;
    z-index: 20;
    min-width: 65px;
    height: 65px;
    border-radius: $halfPart;
    border: 1px solid gray;
    transition: all 0.3s ease-in-out;
    animation: ripple 1.5s ease-out infinite;
  }

  @keyframes ripple {
    0% {
      transform: scale(0.850);
      opacity: 1;
    }

    60% {
      transform: scale(1.5);
      opacity: 0.5;
    }

    61% {
      transform: scale(1.8);
      opacity: 0;
    }

    100% {
      transform: scale(1.8);
      opacity: 0;
    }
  }

  .roundPush {
    width: 55px;
    height: 55px;
    border-radius: $halfPart;
    position: absolute;
    z-index: 5;
    > a {
      right: 15%;
      display: block;
      width: 38px;
      height: 38px;
      position: absolute;
      border-radius: $halfPart;
      cursor: pointer;
      @include miniMobile {
        width: 30px;
        height: 30px;
        right: 26%;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }

    a:nth-child(1) {
      top: -130px;
      animation: telega 1s ease-out;
      transform: translateX($halfPart);
      @include miniMobile {
        top: -110px;
      }
    }

    a:nth-child(2) {
      top: -190px;
      animation: WA 5s ease-out;
      transform: translateX($halfPart);
      @include miniMobile {
        top: -160px;
      }
    }

    .activeBlock {
      top: -70px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--root-color-yellow);
      transform: translateX($halfPart);
      width: 40px;
      height: 40px;
      transition: 1.5s;
      @include miniMobile {
        width: 34px;
        height: 34px;
        right: 22%;
        top: -60px;
      }
      img {
          width: 80%;
          height: 70%;
        }

    }
  }
}

.startBtn {
  rotate: 360deg;
  transition: 0.5s;
  border: 7px solid rgba(0, 0, 0, 1);
}


.btn {
  display: none;
  width: 60px;
  height: 60px;
  margin-top: 30px;
  z-index: 10000;
  background: var(--root-color-yellow);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.4);
  border-radius: 0;
  transition: all 0.3s ease-in-out;

  @include mobile {
    width: 40px;
    height: 40px;
  }

  & svg {
    width: 22.5px;
    height: 11.25px;
  }

  &:is(:hover, :active) {
    background: #ead542;
  }
}

.visible {
  display: flex;
  align-items: center;
  justify-content: center;
}