.Toastify__toast--success .Toastify__toast-body {
  height: 49px;
  border-left: 5px solid #07C41A;
}

.Toastify__toast--error .Toastify__toast-body {
  height: 49px;
  border-left: 5px solid #e74c3c;
}

.Toastify__progress-bar { visibility: hidden }
