@import "../../../../mainStyle/mixins.scss";

.linkOptions {
  position: absolute !important;
  bottom: -80px;
  width: 300px;
  background-color: var(--primary-color);
}

.link {
  display: inline-block;
  font-size: 0;
}

.link .linkWrap {
  display: inline-block;
  text-transform: uppercase;
  color: var(--root-color-white);
  font-weight: 400;
  font-size: 16px;

  @include tablet {
    font-size: 11px;
  }
}

.linkWrap:hover {
  font-weight: 600;
}

.linkWrap::before {
  display: block;
  content: attr(title);
  font-weight: 600;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.links {
  display: flex;
  flex-direction: column;
  position: relative;
}

.link {
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  padding: 10px;

  @include tablet {
    font-size: 11px;
  }
}

.activeLink {
  color: var(--root-color-white);
  font-weight: 600 !important;
  font-size: 16px;

  @include tablet {
    font-size: 11px;
  }
}

.arrowReverse {
  transform: rotate(180deg);
}
