@import url('./helper.scss');
@import url('./nullstyles.scss');

$primary_color: #2C2E35;

:root {
  --root-font-montserrat: 'Montserrat', sans-serif;
  --root-font-grotesk: 'Reforma Grotesk', sans-serif;
  --primary-color: #2C2E35;
  --secondary-color: #9D9D9D;
  --root-color-white: #FFF;
  --root-color-yellow: #FFE743;
  --root-color-gray: #414349;
  --root-color-lightgray: #56585D;
  --root-border-color: #ffffff4b;
  --root-background-dark: #1E1F22;
}


#root {
  background-color: var(--primary-color);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #FFE743;
  border-radius: 10px;
}

// MUI

.Mui-expanded {
  min-height: min-content !important;
}

.MuiPopover-paper {
  background-color: #2C2E35 !important;
}

// MIXINS

@mixin centeredFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin spaceBetweenBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin spaceAroundBlock {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

// FONTS

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  src: local(''),
    url('../assets/fonts/MontserratRegular/Montserrat-Regular.eot') format('eot'),
    url('../assets/fonts/MontserratRegular/Montserrat-Regular.svg') format('svg'),
    /* IE6-IE8 */
    url('../assets/fonts/MontserratRegular/Montserrat-Regular.ttf') format('ttf'),
    /* Safari, android, ios */
    url('../assets/fonts/MontserratRegular/Montserrat-Regular.woff2') format('woff2'),
    /* Legacy ios */
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: normal;
  src: local(''),
    url('../assets/fonts/MontserratMedium/Montserrat-Medium.eot') format('eot'),
    url('../assets/fonts/MontserratMedium/Montserrat-Medium.svg') format('svg'),
    /* IE6-IE8 */
    url('../assets/fonts/MontserratMedium/Montserrat-Medium.ttf') format('ttf'),
    /* Safari, android, ios */
    url('../assets/fonts/MontserratMedium/Montserrat-Medium.woff2') format('woff2'),
    /* Legacy ios */
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: normal;
  src: local(''),
    url('../assets/fonts/MontserratSemiBold/Montserrat-SemiBold.eot') format('eot'),
    url('../assets/fonts/MontserratSemiBold/Montserrat-SemiBold.svg') format('svg'),
    /* IE6-IE8 */
    url('../assets/fonts/MontserratSemiBold/Montserrat-SemiBold.ttf') format('ttf'),
    /* Safari, android, ios */
    url('../assets/fonts/MontserratSemiBold/Montserrat-SemiBold.woff2') format('woff2'),
    /* Legacy ios */
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: normal;
  src: local(''),
    url('../assets/fonts/MontserratBold/Montserrat-Bold.eot') format('eot'),
    url('../assets/fonts/MontserratBold/Montserrat-Bold.svg') format('svg'),
    /* IE6-IE8 */
    url('../assets/fonts/MontserratBold/Montserrat-Bold.ttf') format('ttf'),
    /* Safari, android, ios */
    url('../assets/fonts/MontserratBold/Montserrat-Bold.woff2') format('woff2'),
    /* Legacy ios */
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  font-style: normal;
  src: local(''),
    url('../assets/fonts/MontserratExtraBold/Montserrat-ExtraBold.eot') format('eot'),
    url('../assets/fonts/MontserratExtraBold/Montserrat-ExtraBold.svg') format('svg'),
    /* IE6-IE8 */
    url('../assets/fonts/MontserratExtraBold/Montserrat-ExtraBold.ttf') format('ttf'),
    /* Safari, android, ios */
    url('../assets/fonts/MontserratExtraBold/Montserrat-ExtraBold.woff2') format('woff2'),
    /* Legacy ios */
}

@font-face {
  font-family: 'Reforma Grotesk';
  font-weight: 800;
  font-style: normal;
  src: local(''),
    url('../assets/fonts/ReformaGroteskBold/ReformaGroteskBold.eot') format('eot'),
    url('../assets/fonts/ReformaGroteskBold/ReformaGroteskBold.svg') format('svg'),
    url('../assets/fonts/ReformaGroteskBold/ReformaGroteskBold.ttf') format('ttf'),
    url('../assets/fonts/ReformaGroteskBold/ReformaGroteskBold.woff') format('woff'),
    url('../assets/fonts/ReformaGroteskBold/ReformaGroteskBold.woff2') format('woff2'),
}

@font-face {
  font-family: 'Reforma Grotesk';
  font-weight: 500;
  font-style: normal;
  src: local(''),
    url('../assets/fonts/ReformaGroteskMedium/ReformaGroteskMedium.eot') format('eot'),
    url('../assets/fonts/ReformaGroteskMedium/ReformaGroteskMedium.svg') format('svg'),
    url('../assets/fonts/ReformaGroteskMedium/ReformaGroteskMedium.ttf') format('ttf'),
    url('../assets/fonts/ReformaGroteskMedium/ReformaGroteskMedium.woff') format('woff'),
    url('../assets/fonts/ReformaGroteskMedium/ReformaGroteskMedium.woff2') format('woff2'),
}

@font-face {
  font-family: 'Reforma Grotesk';
  font-weight: 600;
  font-style: normal;
  src: local(''),
    url('../assets/fonts/ReformaGroteskDemi/ReformaGroteskDemi.eot') format('eot'),
    url('../assets/fonts/ReformaGroteskDemi/ReformaGroteskDemi.ttf') format('ttf'),
    url('../assets/fonts/ReformaGroteskDemi/ReformaGroteskDemi.woff') format('woff'),
    url('../assets/fonts/ReformaGroteskDemi/ReformaGroteskDemi.woff2') format('woff2'),
}