@import '../../../mainStyle/mixins.scss';

.ModalResume {
  padding: 24px 24px 31px 24px;
  background: var(--root-color-white);
  max-width: 480px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 454px;
  border: 2px solid #828282;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);

  @include mobile {
    padding: 24px;
    max-width: 398px;
    width: 100%;
  }

  @include miniMobile {
    max-width: 285px;
    padding: 14px 14px 28px;
  }
}

.cancelButton {
  display: flex;
  flex-direction: row-reverse;


  transition: .3s;

  & button {
    width: 40px;
    height: 40px;
  }

  & svg {
    width: 24px;
    height: 24px;
  }
}

.top {
  margin-top: 17px;

  @include mobile {
    margin-top: 2px;
  }
}

.center {
  margin-bottom: 34px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @include mobile {
    & img {
      width: 80px;
      height: 80px;
    }
  }

  @include miniMobile {
    margin-bottom: 20px;

    & img {
      width: 60px;
      height: 60px;
    }
  }
}

.centerBtn {
  margin-bottom: 34px;

  & button {

    span {
      margin-right: 0;
    }
  }

  @include mobile {
    margin-bottom: 0;

    & button {
      max-width: 150px;
      white-space: nowrap;
    }
  }

  @include miniMobile {
    & button {
      max-width: 100%;
    }
  }
}
