// ----- global -----
@mixin desktop {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin mediumDesktop {
  @media (max-width: 980px) {
    @content;
  }
}

@mixin mediumDesktop2 {
  @media (max-width: 960px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 640px) {
    @content;
  }
}

.main {
  & * {
    box-sizing: content-box!important;
    font-family: 'Roboto', Arial, sans-serif;
    color: #000;
  }
}
// ----- mobile -----
.mobile {
  background-color: #111;
  display: none;
  width: 100%;

  @include mediumDesktop {
    display: block;
  }
}

.m_container {
  min-height: 64px;
  padding: 20px;
  position: relative;
  box-sizing: border-box !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.m_name {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.35;
  color: #fff;

  @include desktop {
    font-size: 18px;
  }
  @include mobile() {
    font-size: 16px;
  }
}

.burger {
  position: relative;
  flex-shrink: 0;
  width: 28px;
  height: 20px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 999;

  & span {
    display: block;
    position: absolute;
    width: 100%;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    height: 3px;
    background-color: #fff;
  }

  & span:first-child {top: 0}
  & span:nth-child(2),
  & span:nth-child(3) {top: 8px}
  & span:last-child {top: 16px}

  @include mediumDesktop {
    order: 1;
  }
}


.b_opened {
  & span:first-child,
  & span:last-child {
    top: 8px;
    width: 0%;
    left: 50%;
  }
  & span:nth-child(2) {transform: rotate(45deg)}
  & span:nth-child(3) {transform: rotate(-45deg)}
}
// ----- top -----
.top {
  width: 100%;
  box-shadow: 0 1px 3px rgba(0,0,0,0);
  transition: background-color 300ms linear;

  @include mediumDesktop {
    position: static;
    text-align: center;
    display: none;
    height: 100%;
    margin: 0;
    padding: 0;
    height: auto!important;
  }
}

.t_container {
  max-width: 1200px;
  margin: 0 auto;

  width: 100%;
  display: table;
  position: relative;
}

.topwrapper {
  height: 150px;
  position: relative;
  vertical-align: middle;
  display: table;
  width: 100%;
  box-sizing: border-box!important;

  & .listwrapper {display: none!important}

  @include desktop {padding: 0 20px}
  @include mediumDesktop {
    height: auto!important;
    padding: 0!important;
    width: 100%;
    display: block;

    & .listwrapper {
      display: block!important;
      padding: 20px 0;
    }
  }
}

.alladditional {
  height: inherit;

  @include mediumDesktop {padding: 20px 0}
}

.leftwrapper,
.rightwrapper {
  width: 50%;
  box-sizing: border-box!important;
  position: absolute;
  height: inherit;
  display: table;

  @include mediumDesktop {
    position: static;
    width: 100%;
    padding: 0!important;
    text-align: center;
  }
}

.leftwrapper {
  text-align: left;
  padding-left: 20px;
  padding-right: 175px;
  left: 0;
}

.additionalwrapper {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.additionalitem {
  display: inline-block;
  vertical-align: middle;
  padding-top: 10px;
  padding-bottom: 10px;

  font-size: 13px;
  line-height: 15px;

  & p {text-align: right}

  @include mediumDesktop {
    max-width: 600px;
    margin: 0 auto;
  }
}

.rightwrapper {
  text-align: right;
  padding-right: 20px;
  padding-left: 175px;
  left: 50%;
}

.bottomwrapper {
  height: 40px;
  padding: 0 40px;
  vertical-align: middle;
  display: table;
  width: 100%;
  box-sizing: border-box!important;


  @include desktop {padding: 0 20px}

  @include mediumDesktop {
    padding: 0!important;
    width: 100%;
    display: block;
    height: auto!important;

    & .listwrapper {display: none}
  }
}

.logowrapper {
  position: absolute;
  right: 50%;
  display: table;
  height: inherit;

  @include mediumDesktop {
    position: static;
    display: block;
    width: 100%;
    padding: 20px;
    box-sizing: border-box!important;
  }
}

.logowrapper2 {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  right: -50%;
  z-index: 100;

  & > div {display: block}

  @include mediumDesktop {
    display: block;
    position: static;
  }
}

.logo {
  max-width: 250px !important;
  width: 250px !important;
  height: auto;
  display: block;
  max-width: 300px;

  @include mediumDesktop {
    width: 100%!important;
    box-sizing: border-box!important;
    padding: 20px 0;
    margin: 0 auto;
    float: inherit;
  }
}

.listwrapper {
  display: table-cell;
  vertical-align: middle;
  text-align: center;

  & ul {
    position: relative;

    & li {
      clear: both;
      font-size: 14px;
      display: inline-block;
      padding-left: 15px;
      padding-right: 15px;
      margin: 0;
      white-space: nowrap;

      line-height: 15px;

      &:first-child {padding-right: 0}
      &:last-child {padding-left: 0}

      & a {
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.5px;
      }

      @include mediumDesktop {
        display: block;
        text-align: center;
        padding: 10px!important;
        white-space: normal;
      }
    }
  }

  @include mediumDesktop {
    display: block;
    padding: 20px 0;
  }
}
// ----- bottom -----
.bottom {padding: 60px 0}

.b_container {
  padding: 8px 0 6px;
  position: relative;
}

.b_container2 {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 0;

  &::before, &::after {
    display: table;
    content: " ";
    width: 0;
  }

  &::after {
    clear: both;
  }

  @include desktop {max-width: 960px}
  @include mediumDesktop2 {max-width: 640px}
}

.content {
  display: inline;
  float: left;
  max-width: 760px;
  width: 100%;
  margin: 0 20px;
  padding-left: 200px;

  @include desktop {
    margin: 0 10px;
    max-width: 620px;
  }
  @include mediumDesktop2 {
    display: block;
    float: none;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box!important;
  }
}

.text {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.55;

  @include desktop {font-size: 18px}
  @include mobile {
    font-size: 18px;
    line-height: 1.45;
  }
}
