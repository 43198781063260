@import '../../../mainStyle/mixins.scss';

.title {
  font-family: var(--root-font-grotesk);
  font-style: normal;
  font-weight: 500;
  font-size: 110px;
  line-height: 100%;
  margin-bottom: 40px;
  text-transform: uppercase;

  @include tablet {
    font-size: 50px;
    margin-bottom: 20px;
  }

  @include mobile {
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 400;
    font-size: 40px;
  }

  @include miniMobile {
    font-size: 26px;
    margin-bottom: 16px;
  }
}

.yellow {
  color: var(--root-color-yellow);
}

.white {
  color: var(--root-color-white);
}

.bold {
  font-weight: 800;
  font-size: 120px;

  @include mediumDesktop {
    font-size: 90px;
  }

  @include tablet {
    font-size: 50px;
  }

  @include mobile {
    font-weight: 800;
    font-size: 40px;
  }

  @include miniMobile {
    font-size: 30px;
  }
}
