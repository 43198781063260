@import "../../../mainStyle/mixins.scss";

.block {
  width: 420px;
}

.img {
  position: relative;
  z-index: 1;

  & img {
    width: 85px;
    height: 85px;

    @media (max-width: 835px) {
      width: 95%;
      height: 95%;
    }
  }
}

.imgWrapper {
  min-width: 90px;
  height: 85px;
  position: relative;

  @include mobile {
    min-width: 58px;
    width: 56px;
    height: 58px;
  }
}

.imgSquare {
  position: absolute;
  bottom: -5px;
  right: 0;
  width: 85px;
  height: 85px;
  background: var(--root-color-white);

  @media (max-width: 835px) {bottom: 0}

  @include mobile {
    width: 56px;
    height: 56px;
  }
}

.mainInfo {
  display: flex;
}

.titleWrapper {
  margin-left: 20px;

  @include miniMobile {
    margin-left: 8px;
  }
}

.position {
  font-family: var(--root-font-montserrat);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: var(--primary-color);
  opacity: 0.5;
  margin-bottom: 8px;

  @include mobile {
    font-size: 10px;
  }
}

.nameInfo {
  font-family: var(--root-font-montserrat);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: var(--primary-color);

  @include mobile {
    font-size: 14px;
  }
}

.subTitle {
  line-height: 21px !important;
  margin-top: 20px !important;

  @include miniMobile {
    font-size: 12px !important;
    margin-top: 11px;
  }
}

@include mediumDesktop {
  .block {
    max-width: 360px;
    width: 100%;

    @include mobile {
      max-width: 296px;
    }

    @include miniMobile {
      max-width: 288px;
      width: 100%;
      margin-top: 12px;
    }
  }
}

@include tablet {
  .block {
    max-width: 360px;
  }

  .nameInfo {
    font-size: 18px;
  }

  .position {
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.02em;
    margin-top: 8px;
    margin-bottom: 0;
  }

}
