//mixins
@mixin mobile {
  @media (max-width: 834px) {
    @content;
  }
}

@mixin mediumDesktop {
  @media (max-width: 1485px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: 430px) {
    @content;
  }
}
