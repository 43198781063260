@import '../../../mainStyle/mixins.scss';

.inp {
  border: 1px solid #C2C2C2;
  background: var(--root-color-white);
  border-radius: 8px;
  height: 32px;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 20px;

  @include mobile {
    padding: 12px;
    height: 44px;
  }
}

.inpError {
  border: 1px solid #B40000;
}

.Error {
  font-family: var(--root-font-montserrat);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #B40000;

  @include mobile {
    font-size: 12px;
  }
}
