@import "../../../mainStyle/mixins.scss";

.sectionBlock {
  padding-top: 5%;
  padding-bottom: 5%;

  @include mediumDesktop {
    padding: 80px 0;
  }

  @include tablet {
    padding: 60px 0;
  }

  @include mobile {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include miniMobile {
    padding: 40px 0 20px;
  }
}

.tempDarkBg {
  background-color: var(--primary-color);
  color: var(--root-color-white);

  .cardsText {
    border: 1px solid var(--root-border-color);
  }

  .cardsImg {
    border: 1px solid var(--root-border-color);
  }

  .mainTitle {
    color: var(--root-color-yellow);
  }
}

.tempWhiteBg {
  background-color: #ffffff;
  color: var(--primary-color);

  .cardsText {
    border: 1px solid #5f5e5e72;
  }

  .cardsImg {
    border: 1px solid #5f5e5e72;
  }

  .mainTitle {
    color: #2c2c2c;
  }
}

.mainBlock {
  display: flex;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
  }
}

.leftToRight,
.rightToLeft {
  display: flex !important;
}

.leftToRight {
  flex-direction: row;
}

.rightToLeft {
  flex-direction: row-reverse;
}

.leftBlock {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 25%;
  row-gap: 24px;

  @include tablet {
    max-width: 284px;
    width: 100%;
    row-gap: 20px;
  }

  @include mobile {
    max-width: 100%;
    width: 100%;
    row-gap: 20px;
    margin-bottom: 20px;
  }

  @include miniMobile {
    max-width: 100%;
    row-gap: 10px;
    margin-bottom: 6px;
  }
}

.mainTitle {
  font-family: var(--root-font-grotesk);
  font-weight: 800;
  display: flex;
  flex-wrap: wrap;
  font-size: 70px;
  line-height: 100%;
  text-transform: uppercase;
  max-width: 467px;
  width: 100%;
  margin-bottom: 16px;

  @include mediumDesktop {
    font-size: 46px;
  }

  @include tablet {
    font-size: 5vw;
    line-height: 100%;
    width: 100%;
    max-width: 284px;
    margin-bottom: 0;
  }

  @include mobile {
    white-space: pre-wrap;
    width: 97%;
    font-size: 40px;
    max-width: 370px;
  }

  @include miniMobile {
    font-size: 26px;
    margin-bottom: 0px;
    width: 100%;
  }
}

.titleUnder {
  font-family: var(--root-font-montserrat);
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  width: 350px;

  @include mediumDesktop {
    font-size: 20px;
    width: 300px;
  }

  @include tablet {
    font-size: 10px;
    line-height: 140%;
    width: 100%;
  }

  @include mobile {
    width: 101%;
    font-size: 16px;
  }

  @include miniMobile {
    font-size: 12px;
    line-height: 140%;
  }
}

.buttonBox {
  width: 90%;
  white-space: nowrap !important;

  @include mobile {
    margin-bottom: 35px;
  }

  @include miniMobile {
    width: 100%;
    margin-bottom: 16px !important;

    & a {
      width: 100%;

      span {
        font-size: 14px !important;
      }
    }
  }
}

.tb {
  display: flex !important;
  flex-direction: column;
  align-items: flex-end;

  @include mobile {
    align-items: flex-start;
  }
}

.rightText,
.rightImage {
  width: 466px;

  @include mediumDesktop {
    max-width: 346px;
    width: 100%;
  }

  @include mobile {
    max-width: 100% !important;
    width: 100% !important;
  }

  @include miniMobile {
    width: 100%;
  }
}

.rightText {
  padding: 60px !important;
  border: 1px solid #c9c9c9;
  font-family: var(--root-font-montserrat) !important;
  font-size: 16px !important;
  line-height: 160% !important;
  color: #2c2e35 !important;
  white-space: pre-wrap;

  ul {
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    & li {
      list-style: outside;
    }
  }

  p {
    font-family: var(--root-font-montserrat) !important;
    font-size: 16px !important;
    line-height: 160% !important;
  }

  span {
    line-height: normal !important;
  }

  & * {
    max-width: 345px;

    & ul {
      display: flex;
      flex-direction: column;
      row-gap: 32px;

      & li {
        list-style: outside;
      }
    }
  }

  @include mediumDesktop {
    padding: 25px !important;
    font-size: 14px !important;
    line-height: 160% !important;

    ul {
      padding-left: 20px;
      font-size: 14px !important;
      line-height: 160% !important;
    }

    p {
      font-size: 14px !important;
      line-height: 160% !important;
    }

    & * {
      font-size: 14px !important;
      line-height: 160% !important;

      & ul {
        padding-left: 20px;
        font-size: 14px !important;
        line-height: 160% !important;
      }
    }

    & div ul li b {
      font-size: 16px !important;
      line-height: 160% !important;
      font-weight: 600;
    }
  }

  @include tablet {
    padding: 14px 18px !important;
    padding: 14px 18px !important;
    font-size: 16px !important;

    ul {
      padding-left: 10px;
      row-gap: 18px;

      & li {
        list-style: disc;
        margin-left: 18px;

        & * {
          font-size: 16px !important;
          line-height: 130% !important;
        }
      }
    }

    p {
      font-size: 16px !important;
    }

    b {
      font-size: 18px !important;
    }

    & {
      font-size: 16px !important;

      b {
        font-size: 16px !important;
      }

      & ul {
        padding-left: 10px;
        row-gap: 18px;

        & li {
          list-style: disc;
          margin-left: 18px;

          & * {
            font-size: 16px !important;
            line-height: 130% !important;
          }
        }
      }
    }
  }

  @include mobile {
    padding: 14px 18px !important;
    font-size: 14px !important;

    ul {
      padding-left: 10px;
      row-gap: 18px;

      & li {
        list-style: disc;
        margin-left: 18px;

        & * {
          font-size: 16px !important;
          line-height: 130% !important;
        }
      }
    }

    p {
      font-size: 14px !important;
    }

    b {
      font-size: 16px !important;
    }

    & {
      font-size: 14px !important;

      b {
        font-size: 16px !important;
      }

      & ul {
        padding-left: 10px;
        row-gap: 18px;

        & li {
          list-style: disc;
          margin-left: 18px;

          & * {
            font-size: 16px !important;
            line-height: 130% !important;
          }
        }
      }
    }
  }

  @include miniMobile {
    padding: 12px !important;
    font-size: 14px !important;

    ul {
      padding-left: 0 !important;
      row-gap: 18px;

      li {
        font-size: 14px !important;
        line-height: 130% !important;

        & * {
          font-size: 14px !important;
          line-height: 130% !important;
        }
      }
    }

    p {
      font-size: 14px !important;
    }

    b {
      font-size: 14px !important;
    }

    & * {
      font-size: 14px !important;

      b {
        font-size: 16px !important;
      }

      & ul {
        padding-left: 0 !important;
        row-gap: 18px;

        li {
          font-size: 14px !important;
          line-height: 130% !important;

          & * {
            font-size: 14px !important;
            line-height: 130% !important;
          }
        }
      }
    }
  }
}

.rightImage {
  padding: 40px !important;
  border: 1px solid #c9c9c9;

  & img {
    width: 386px;
    max-height: 520px;
    height: 520px;
    object-fit: cover;
  }

  @include mediumDesktop {
    padding: 20px !important;

    & img {
      max-width: 306px;
      height: 414px;
      width: 100%;
    }
  }

  @include tablet {
    padding: 14px 18px !important;

    & img {
      max-width: 324px;
      height: 196px;
      width: 100%;
      object-fit: cover;
    }
  }

  @include mobile {
    padding: 18px 14px !important;
    border: 1px solid #c9c9c9;

    & img {
      max-width: 100%;
      height: 220px;
      object-fit: cover;
      object-position: 0 center;
    }
  }

  @include miniMobile {
    padding: 12px !important;

    & img {
      width: 100%;
      height: 160px;
    }
  }
}

.cardsImg {
  width: 50%;
  padding: 4%;
}

.cardsImg img {
  width: 100%;
}

.cardInfoTittle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: var(--primary-color);
}

.whiteText {

  &,
  & p {
    color: #fff !important;
    font-weight: 400;

    & b {
      font-weight: 600 !important;
    }


    & ul li,
    span {
      list-style: disc;
      color: #fff !important;
    }
  }
}

.text {
  font-size: 16px;
  line-height: 26px;
  color: #2c2e35;
}

@include tablet {
  .tableMobileMode {
    width: 100%;
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
    max-width: 100% !important;
    align-items: flex-end;
  }
}

@include mobile {
  .tableMobileMode {
    width: 100%;
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
    max-width: 100% !important;
  }
}