@import '../../../mainStyle/mixins.scss';

.ticker {
  opacity: .2;
  display: flex;
}

.ticker:first-child {
  margin-bottom: 10px;

  @include mobile {
    margin-bottom: 4px;
  }
}

.text {
  color: transparent;
  font-size: 150px !important;
  line-height: 124px !important;
  font-family: var(--root-font-grotesk), sans-serif !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  -webkit-text-stroke: .5px var(--root-color-white);
  word-spacing: -13px;
  overflow: hidden;

  @include tablet {
    font-size: 80px !important;
    line-height: 83% !important;
  }

  @include mobile {
    font-size: 80px !important;
    line-height: 66px !important;
  }

  @include miniMobile {
    font-size: 60px !important;
    line-height: 83% !important;
  }
}
