.bgParent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 20px;
  height: 20px;
  gap: 5px;
  position: relative;
  cursor: pointer;

  .bgItem {
    width: 20px;
    height: 1px;
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 4px;
    transition: all 0.3s ease;
  }

  .bgItemActive {
    position: absolute;
    width: 20px;
    height: 1px;
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 4px;
    transition: all 0.3s ease;

    &:nth-child(1) {
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
}

.name {
  text-transform: uppercase;
  color: #ffffff;
  line-height: 24px !important;
  font-size: 12px !important;
  padding: 3px 10px;
}
