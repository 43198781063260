@import '../../mainStyle/mixins.scss';

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.load {
  position: absolute;
  top: 30%;
  left: 45%;
  width: 100px;
  height: 100px;
  margin: 110px auto 0;
  border: solid 10px #FFE743;
  border-radius: 50%;
  border-bottom-color: transparent;
  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1.0s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.loaderBlock {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

@include mobile {
  .load {
    width: 60px;
    height: 60px;
    border: solid 8px #FFE743;
    border-bottom-color: transparent;
    margin: 0;
  }

  .loaderBlock {
    height: 100vh;
  }
}
