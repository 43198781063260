@import "../../../mainStyle/mixins.scss";

.btn {
  font-family: var(--root-font-montserrat);
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 22px !important;
  text-align: left;
  padding: 12px 24px !important;
  transition: 0.3s ease-out !important;

  @include mobile {
    gap: 7px;
  }

  @include mediumDesktop {
    span {
      font-size: 14px;
    }
  }

  @include tablet {
    span {
      font-size: 14px !important;
      line-height: 17px !important;
    }
  }

  @include mobile {
    width: 252px;
    height: 48px;

    & span {
      font-size: 14px !important;
      line-height: 17px !important;
    }
  }

  @include miniMobile {
    width: 288px;

    & span {
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 17px !important;
    }
  }
}

.btnText {
  font-weight: 500;
  font-size: 18px;
}

.btnWhiteTheme {
  background-color: var(--root-color-white) !important;
  color: var(--primary-color) !important;
  border: 2px solid var(--root-color-white) !important;
}

.btnWhiteTheme:hover {
  background: #eaeaeb !important;
  border: 2px solid #eaeaeb !important;
}

.btnWhiteTheme:active {
  background: #d5d5d7 !important;
  border: 2px solid #d5d5d7 !important;
}

.btnRedTheme {
  background-color: #ff00001f !important;
  border: 1px solid red !important;
  color: var(--root-color-white) !important;
  height: 51px;
  max-height: 51px;
  border-radius: 8px !important;
}

.btnRedTheme:hover {
  background: #ff000047 !important;
}

.btnRedTheme:active {
  background: #ff000047 !important;
}

.btnYellowTheme {
  background-color: var(--root-color-yellow) !important;
  color: var(--primary-color) !important;
}

.btnYellowTheme:hover {
  background: #ead542 !important;
}

.btnYellowTheme:active {
  background: #d5c240 !important;
}

.btnTransparentTheme {
  background: none !important;
  color: var(--root-color-white) !important;
}

.btnDarkTheme {
  background: var(--primary-color) !important;
  color: var(--root-color-white) !important;
  border: 1px solid var(--root-color-white) !important;
}

.btnDisabledTheme {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #999999 !important;
  color: #2C2E35 !important;
}

.rounded {
  border-radius: 100px !important;
}

.outlined {
  background: none !important;
  color: var(--root-color-white) !important;
  overflow: hidden;
  border: 2px solid var(--root-color-white) !important;

  @include mobile {
    border: 1px solid var(--root-color-white) !important;
  }
}

.outlined:hover {
  background: #414349 !important;
}

.outlined:active {
  background: #56585d !important;
}