@import '../../../mainStyle/mixins.scss';

.ModalResume {
  padding: 24px;
  background: var(--root-color-white);
  max-width: 480px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #828282;

  @include mobile {
    max-width: 398px;
    width: 100%;
  }

  @include miniMobile {
    max-width: 291px;
    padding: 16px;
  }
}

.title {
  font-weight: 600 !important;
  color: var(--primary-color) !important;

  @include mobile {
    font-size: 20px !important;
    line-height: 24px !important;
  }

  @include miniMobile {
    font-size: 16px;
    line-height: 20px;
  }
}

.resumeHeader {
  position: relative;
}

.inpWrapper {
  margin-top: 24px;

  @include miniMobile {
    margin-top: 18px;
  }
}

.inpTitle {
  margin-bottom: 4px !important;
  line-height: 150% !important;

  @include mobile {
    margin-top: 8px !important;
  }
}

.attachIcon {
  width: 24px !important;
  height: 24px !important;
  transform: rotate(45deg);
}

.wrapPinLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 432px;
  min-height: 84px;
  border: 1px dashed #C2C2C2;
  padding: 20px;

  @include mobile {
    max-width: 348px;
    width: 100%;
    min-height: 88px;
  }

  @include miniMobile {
    padding: 22px 11.5px;
  }
}

.pinLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pinTitlte {
  font-family: var(--root-font-montserrat);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-decoration-line: underline;
  color: var(--primary-color);

  @include miniMobile {
    white-space: nowrap;
  }
}

.pinSubtitle {
  font-family: var(--root-font-montserrat);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #757575;
  margin-top: 4px;

  @include mobile {
    font-size: 12px;
  }
}

.pinSubtitle:nth-child(2) {
  text-align: center;
}

.textarea {
  resize: none;
  border: 1px solid #C2C2C2;
  height: 74px;
  width: 100% !important;
  padding: 4px 8px;

  @include mobile {
    height: 120px;
  }
}

.textarea::-webkit-scrollbar {
  width: 6px;
}

.textarea::-webkit-scrollbar-thumb {
  background: var(--root-color-yellow);
  border-radius: 5px;
}

.btnWrapper {
  display: flex;
  justify-content: right;

  button {
    margin-left: 20px;

    @include mobile {
      margin-left: 12px;
    }

    @include miniMobile {
      margin-left: 0;

      &:first-child {
        max-width: 116px;
      }

      &:nth-child(2) {
        max-width: 127px;
      }
    }
  }

  @include miniMobile {
    justify-content: space-between;
  }
}

.file {
  padding: 4px 8px;
  background: #F4F4F4;
  opacity: 0.8;
  display: flex;
  align-items: center;
  width: fit-content;

  img {
    margin-right: 8px;
  }

  span {
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-color);
    margin-right: 14px;
  }
}

.inpError:after {
  content: ' *';
  color: #B40000;
  font-weight: 500 !important;
}

.error {
  color: #B40000;
}

.posLeft {
  text-align: right;
}

.btnClose {
  position: absolute !important;
  top: -2px;
  right: 0;
  width: 40px;
  height: 40px;
  transition: .3s;

  & svg {
    width: 24px;
    height: 24px;
  }
}

@include mobile {
  .btnClose {
    top: -6px;
    right: -3%;
  }
}
