@import "../../../mainStyle/mixins.scss";

.main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  text-transform: uppercase;
  font-family: var(--root-font-grotesk);
  font-size: 70px;
  font-weight: 800;
  line-height: 110%;
  color: var(--primary-color);
  white-space: pre-wrap;

  @include mediumDesktop {
    font-size: 58px;
  }
}

.colorWhite {
  color: var(--root-color-white);
}

.colorYellow {
  color: var(--root-color-yellow);
}

.buttons {
  display: flex;
  column-gap: 24px;

  & .btn {
    width: 94.5px !important;
    height: 94.14px !important;
    cursor: pointer;
    background: var(--primary-color) !important;

    &:hover {
      background: linear-gradient(0deg,
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0.1)),
        var(--primary-color) !important;
    }

    &:active {
      background: linear-gradient(0deg,
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0.2)),
        var(--primary-color) !important;
    }
  }

  & .btnWhite {
    background: var(--root-color-white) !important;

    & svg {
      color: var(--primary-color) !important;
    }

    &:hover {
      background: linear-gradient(0deg,
          rgba(44, 46, 53, 0.1),
          rgba(44, 46, 53, 0.1)),
        var(--root-color-white) !important;
    }

    &:active {
      background: linear-gradient(0deg,
          rgba(44, 46, 53, 0.2),
          rgba(44, 46, 53, 0.2)),
        var(--root-color-white) !important;
    }
  }
}

.swiper {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
}

.cont {
  margin-top: 40px;
  width: 100%;
  padding-left: calc(calc(100vw - 1440px) / 2);
}

.swiper::-webkit-scrollbar {
  display: none;
}

.container {
  max-width: 1440px;
  padding-left: 0;
  margin: 40px auto 0;
}

.subTitle {
  font-family: var(--root-font-montserrat);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140% !important;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 20px;
  max-width: 409px;
}

@include mediumDesktop {
  .cont {
    margin-top: 40px;
    width: 100%;
    padding-left: calc(calc(100vw - 82.5%) / 2);
  }

  .container {
    padding: 0 120px;
  }
}

@include tablet {
  .cont {
    margin-top: 20px;
    padding-left: calc(calc(100vw - 80.5%) / 2);
  }

  .buttons {
    column-gap: 14.5px;

    & .btn {
      width: 69.5px !important;
      height: 69.5px !important;
    }
  }

  .text {
    font-size: 50px;
    line-height: 100%;
  }

  .subTitle {
    font-size: 12px;
    line-height: 140%;
    margin-top: 10px;
  }

  .container {
    padding: 0 80px;
  }
}

@include mobile {
  .buttons {
    column-gap: 12px;

    & .btn {
      width: 44px !important;
      height: 44px !important;
    }
  }

  .text {
    max-width: 260px;
    font-size: 40px;
    line-height: 110%;

    @include miniMobile {
      font-size: 26px;
      line-height: 110%;
      max-width: fit-content;
    }
  }

  .cont {
    padding-left: calc(calc(100vw - 92%) / 2);
    margin-top: 20px;
    padding-top: 40px;
    padding-top: 0;

    @include miniMobile {
      margin-top: 16px;
      padding-left: calc(calc(100vw - 90%) / 2);
    }
  }

  .container {
    padding: 0 16px;
  }

  .main {
    align-items: start;

    @include miniMobile {
      align-items: center;
    }
  }

  .subTitle {
    font-size: 12px;
  }
}

@include miniMobile {
  .container {
    padding: 0 16px;
  }

  .subTitle {
    max-width: 250px;
    font-size: 12px;
    line-height: 140%;
    margin-top: 7px;
  }

  .main {
    position: relative;
    margin-top: 7px;
    font-size: 8px;
  }
}

@media(min-width: 1920px) {
  .swiper {
    width: 1440px;
  }

  .cont {
    padding-left: calc(calc(100vw - 100%) / 2);
  }
}
