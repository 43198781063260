@import "../../../mainStyle/mixins.scss";
@import url("../../../mainStyle/global.scss");

$divider: 238px;
$divider-height: 8px;
$divider-mobile: 100px;
$divider-height-mobile: 2px;
$color: var(--root-color-white);
$second_color: var(--root-color-yellow);
$line_height_font: 150px;


.block {
  margin: 145px 0 117px;

  @include tablet {
    margin: 80px 0 140px;
  }

  @include mobile {
    margin: 60px 0 60px;
  }

  @include miniMobile {
    margin: 40px 0 20px;
  }
}

.divider {
  position: absolute;
  top: 344px;
  left: -1px;
  border-color: $second_color !important;
  width: $divider;
  border-width: $divider-height !important;
  transform: translateX(calc(-50% + $divider-height)) rotate(90deg);

  animation: animate 0.2s ease-in;

  @keyframes animate {
    0% {
      transform: translateX(calc(calc(-50% + $divider-height) - $divider-height * 2)) rotate(90deg);

      @include mobile {
        transform: translateX(calc(calc(-50% + $divider-height-mobile) - $divider-height-mobile * 2)) rotate(90deg);
      }
    }

    100% {
      transform: translateX(calc(-50% + $divider-height)) rotate(90deg);

      @include mobile {
        transform: translateX(calc(-50% + $divider-height-mobile)) rotate(90deg);
      }
    }
  }

  @include mediumDesktop {
    width: 175px;
    top: 295px;
  }

  @include tablet {
    width: 160px;
    top: 220px;
    border-width: 6px !important;
  }

  @include mobile {
    top: 171px;
    width: $divider-mobile;
    border-width: $divider-height-mobile !important;
    transform: translateX(calc(-50% + $divider-height-mobile)) rotate(90deg);
  }

  @include miniMobile {
    top: 135px;
    width: 70px;
    border-width: 4px !important;
  }
}

.nothing {
  @include mobile {
    display: none !important;
  }
}

.mlNone {
  margin: 0 0 20px !important;
}

.title,
.whiteText,
.yellowText {
  max-width: 100%;
  color: $second_color !important;
  text-transform: uppercase;
  font-family: var(--root-font-grotesk), sans-serif !important;
  font-weight: 800 !important;
  font-size: $line_height_font !important;
  line-height: $line_height_font !important;

  & > span {
    display: inline-flex;
    align-items: center;
  }

  @include mediumDesktop {
    font-size: 80px !important;
    line-height: 90px !important;
  }

  @include tablet {
    font-size: 80px !important;
    line-height: 100% !important;
  }

  @include mobile {
    font-size: 50px !important;
    line-height: 50px !important;
  }

  @include miniMobile {
    font-size: 36px !important;
    line-height: 100% !important;
  }
}

.inFlex {
  display: inline-flex;
}

.whiteText {
  white-space: nowrap;
  color: $color !important;

  & * {
    white-space: pre-wrap;
  }
}

.graphic,
.graphic span {
  white-space: nowrap;

  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;

  @include mediumDesktop {
    font-size: 12px !important;
    line-height: 140% !important;
  }

  @include miniMobile {
    font-size: 12px !important;
    line-height: 140% !important;
  }
}

.graphic {
  max-width: 200px;

  @include mediumDesktop {
    max-width: 170px;
  }

  @include tablet {
    max-width: 160px;
  }

  @include miniMobile {
    max-width: 160px;
  }
}

.graphic:last-child {
  width: 219px;

  & span {
    display: inline-block;
    margin-bottom: auto;
  }
}

.date {
  margin-left: 32px;
  position: relative;
  display: inline-block;
}

.inner_date {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @include mediumDesktop {
    row-gap: 10px;
  }

  @include mobile {
    row-gap: 10px;
  }

  @include miniMobile {
    row-gap: 6px;
  }
}
.lastBlock{
  display: block !important;
  width: 300px;
}

.graphic span:first-child {
  color: $color !important;
  margin-right: 5px;
  text-transform: uppercase;
}

.graphic span {
  display: inline-block;
  color: $second_color;
}

.description {
  max-width: 1120px;
  font-weight: 500 !important;
  font-size: 30px !important;
  line-height: 140% !important;
  text-transform: uppercase;
  margin: 24px 0 !important;
  color: $color;

  @include mediumDesktop {
    font-size: 24px !important;
    margin: 20px 0 !important;
  }

  @include tablet {
    font-size: 20px !important;
  }

  @include mobile {
    margin: 20px 0 !important;
    font-size: 18px !important;
    line-height: 25px !important;
  }

  @include miniMobile {
    margin: 12px 0 16px !important;
    font-size: 12px !important;
    line-height: 140% !important;
  }

}

.technologyBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.technologyUpperBlock {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}
