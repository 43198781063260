@import "../../mainStyle/mixins.scss";

.wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--root-background-dark);
  padding: 28px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  @include mediumDesktop {
    padding: 40px 80px !important;
  }
  @media (max-width: 674px) {
    padding: 40px 16px !important;
  }
}
.banner {  
  width: 1440px;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .info {
    display: flex;
    align-items: center;
    gap: 50px;
    img {
      width: 40;
      height: 40px;
    }
    @media (max-width: 674px) {
      gap: 20px !important;
    }
  }
}

.text,
.link {
  font-family: var(--root-font-montserrat);
  font-weight: 500;
  font-size: clamp(6px, 2vw, 16px);
  text-transform: uppercase;  
  color: var(--root-color-white);
}

.link {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  img {
    width: clamp(11px, 5vw, 40px);
    margin-left: 12px;
    height: auto;
    vertical-align: middle;
  }
}

.button {
  display: flex;
  font-size: clamp(6px, 2vw, 16px);
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius: 4px;
  padding: 12px 24px;
  background-color: var( --root-color-yellow);
  @media (max-width: 570px) {
    padding: 5px 14px;
  }
}
