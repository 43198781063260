@import "../../mainStyle/mixins.scss";

.header {
  flex-grow: 1;
  position: sticky !important;
  height: 64px;
  z-index: 100;
  top: 0;
  background: var(--primary-color);
  .toolBar {
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
    gap: 15px;

    .logo {
      cursor: pointer !important;
    }

    .flexList {
      display: flex;
      align-items: center;
      gap: 16px;
      position: relative;
    }

    @media (max-width: 1380px) {
      display: none;
    }

    @include mobile {
      display: none;
    }
  }

  .mobileToolbar {
    display: none;

    .logo {
      max-width: 100px;
      max-height: 44px;
      cursor: pointer !important;
      margin-right: 5px;
    }

    .flexList {
      display: flex;
      align-items: center;
      position: relative;
    }

    @media (max-width: 1380px) {
      display: flex;
      justify-content: space-between;
      padding: 0;
      width: 100% !important;
    }

    @include mobile {
      display: flex;
      justify-content: space-between;
      padding: 0;
      width: 100% !important;
    }
  }
}
.headerContainer {
  height: 100%;
}
.headerBtn {
  & > span {
    font-size: 16px !important;
    font-weight: 500;
    line-height: 19.5px;
  }
  & > svg {
    height: 24px;
    width: 24px;
  }
}